import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Logo from "../images/logo.svg";

import { Link } from "gatsby";
const Footer = () => {
  const {
    Footer: {
      frontmatter: { backgroundImage, logo, address, phone_number, vat_number },
    },
  } = useStaticQuery(graphql`
    query Footer {
      Footer: markdownRemark(fileAbsolutePath: { regex: "/footer/" }) {
        frontmatter {
          backgroundImage: background {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logo: logo {
            publicURL
          }
          address
          phone_number
          vat_number
        }
      }
    }
  `);
  return (
    <div className="border-t border-gray-300 pt-8">
      <div
        className="pt-8 pb-12 md:pb-24 xl:pb-32 xl:px-32 footer-main w-full px-5 md:px-8  "
        style={{
          backgroundImage: `url(${backgroundImage.childImageSharp.fluid.src})`,
        }}
      >
        <div className="flex  flex-wrap-reverse lg:flex-no-wrap justify-between items-start">
          <div className="w-full lg:w-auto mt-5 lg:mt-0">
            <h3 className="text-lg lg:text-xl text-blackish font-bold">
              Developed by{" "}
              <a
                rel="noreferrer noopener"
                href="https://elementsoftworks.co.uk/"
                target="_blank"
              >
                {" "}
                Element Softworks Ltd
              </a>
            </h3>
            <p className="text-md lg:text-xl text-textcolor mt-2">
              Registered company in {address}
              <span className="block">
                {" "}
                Company Number {phone_number} | VAT Number {vat_number} .
              </span>
            </p>
            <div className="mt-16">
              <ul className="flex flex-wrap footer-links">
                {[
                  {
                    title: "Privacy Policy",
                    route: "/privacy-policy",
                  },
                  {
                    title: "Terms of Service",
                    route: "/terms-of-service",
                  },
                  {
                    title: "Contact Us",
                    route: "/contact",
                  },
                ].map((item) => {
                  return (
                    <li className="mr-8 text-md lg:text-xl text-textcolor cursor-pointer w-full lg:w-auto mb-2 lg:mb-0">
                      <Link to={item.route}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="w-1/3 md:w-1/5 lg:w-1/6">
            <div className="w-full xl:w-4/5 mx-auto">
              <Logo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
