import React from "react";
import Helmet from "react-helmet";

function SEO({ seo }) {
  const { title, description, previewURL } = seo;
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/images/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/images/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/static/images/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/static/images/favicon/safari-pinned-tab.svg"
        color="#3d5e67"
      />
      <link rel="shortcut icon" href="/static/images/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#3d5e67" />
      <meta
        name="msapplication-config"
        content="/static/images/favicon/browserconfig.xml"
      />
      <meta name="theme-color" content="#ffffff" />
      {/* Primary Meta Tags */}
      <title>{`${title} - Merlin Panel`}</title>
      <meta name="title" content={`${title} - Merlin Panel`} />
      <meta name="description" content={description} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://merlinpanel.com/" />
      <meta property="og:title" content={`${title} - Merlin Panel`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={previewURL} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://merlinpanel.com/" />
      <meta property="twitter:title" content="Merlin Panel" />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={previewURL} />
      <html lang="en" />
      <link rel="canonical" href="https://merlinpanel.com/" />
    </Helmet>
  );
}

export default SEO;
