import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";
function Layout({ children, home }) {
  return (
    <div className="">
      <Header home={home} />
      <main className="w-full bg-white">{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
