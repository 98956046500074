import React, { useState, useEffect, useReducer } from "react";
import Logo from "../images/svgs/logo-white.svg";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
const Header = ({ home }) => {
  const [isExpanded, toggleExpansion] = useState(false);
  const [packages, togglepackages] = useState(false);
  const [features, tooglefeatures] = useState(false);
  const [user, setuser] = useState(null);
  const { Packages, Features, Commonfeatures } = useStaticQuery(graphql`
    query packages {
      Packages: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/packages/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              path
              id
              nav
              subtitle
              logo {
                publicURL
              }
            }
          }
        }
      }
      Features: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/core_features/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              nav
              id
              path
            }
          }
        }
      }
      Commonfeatures: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/common_features/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              id
              path
            }
          }
        }
      }
    }
  `);

  return (
    <div
      className={`absolute ${
        !home && "bg-theme"
      } top-0 left-0 right-0 block z-40`}
    >
      <div
        className={`${
          isExpanded ? "w-full opacity-100" : "w-0 opacity-0"
        } xl:h-0 mobile-menu lg:hidden fixed bg-theme z-30 px-5 py-6`}
      >
        {isExpanded && (
          <>
            {" "}
            <div className="flex justify-between items-center">
              <div className="w-2/5">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <span
                className="text-4xl text-white font-bold block md:hidden"
                onClick={() => {
                  toggleExpansion(false);
                }}
              >
                &#9776;
              </span>
            </div>
            <div className=" pb-5  text-white">
              <ul className="text-2xl pt-12 flex flex-col mobile-header-navigation">
                <li className="mb-4">
                  <div className="flex items-center">
                    <span
                      onClick={() => {
                        togglepackages(!packages);
                        tooglefeatures(false);
                      }}
                      className="mr-4"
                    >
                      Packages
                    </span>
                    <p
                      onClick={() => {
                        togglepackages(!packages);
                        tooglefeatures(false);
                      }}
                    >
                      ▾
                    </p>
                  </div>
                  {packages && (
                    <ul className="text-xl text-navdrop mt-2 w-40 ">
                      {Packages.edges.map((edge) => {
                        return (
                          <li className="mb-2 ">
                            <Link
                              to={edge.node.frontmatter.path}
                              onClick={() => {
                                togglepackages(!packages);
                              }}
                              className="flex justify-between items-center"
                            >
                              <span>{edge.node.frontmatter.nav}</span>
                              <div className="w-1/6">
                                <img
                                  alt="logo"
                                  src={edge.node.frontmatter.logo.publicURL}
                                ></img>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
                <li className="mb-4">
                  <Link
                    to="/about"
                    spy={true}
                    smooth={true}
                    duration={1100}
                    hashSpy={true}
                    onClick={() => {
                      toggleExpansion(false);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li className="relative mb-4">
                  <div className="flex items-center">
                    <Link to="/features" className="flex items-center">
                      <span className="mr-4">Features</span>
                    </Link>
                    <p
                      onClick={() => {
                        togglepackages(false);
                        tooglefeatures(!features);
                      }}
                    >
                      ▾
                    </p>
                  </div>
                  {features && (
                    <ul className="text-xl text-navdrop mt-2 w-40">
                      {Features.edges.map((edge) => {
                        return (
                          <li className="mb-2">
                            <Link
                              to={edge.node.frontmatter.path}
                              onClick={() => {
                                tooglefeatures(!features);
                              }}
                            >
                              <span>{edge.node.frontmatter.nav}</span>
                            </Link>
                          </li>
                        );
                      })}
                      <li>
                        <ul className="mt-2 pt-2 border-t border-textcolor">
                          {Commonfeatures.edges.map((edge) => {
                            return (
                              <li className="mb-2">
                                <Link
                                  to={edge.node.frontmatter.path}
                                  onClick={() => {
                                    tooglefeatures(!features);
                                  }}
                                >
                                  <span>{edge.node.frontmatter.title}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="mb-4">
                  <Link
                    to="/showcase"
                    spy={true}
                    smooth={true}
                    duration={1100}
                    hashSpy={true}
                    onClick={() => {
                      toggleExpansion(false);
                    }}
                  >
                    Showcase
                  </Link>
                </li>
                <li className="xl:ml-16 md:ml-8 cursor-pointer">
                  <a
                    href="http://docs.merlinpanel.com/"
                    target="_blank"
                    className="text-white"
                  >
                    Docs
                  </a>
                </li>
                <li className="mt-2 mb-4">
                  {!!user ? (
                    <div className="menu-avatar">
                      <img alt="Avatar" src={user?.avatar} />
                    </div>
                  ) : (
                    <a
                      rel="noreferrer noopener"
                      href="https://app.merlinpanel.com/"
                      target="_blank"
                    >
                      <button className="bg-transparent text-sm border border-white text-white rounded-md px-6 py-2 outline-none">
                        Login
                      </button>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className=" max-w-6xl mx-auto px-5 md:px-8 xl:px-0 flex justify-between py-6 items-center">
        <span id="main-logo" className="w-1/2 lg:w-1/4 xl:w-1/6">
          <div className="w-9/12">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </span>

        <div className="flex justify-end items-center">
          <span
            className="text-4xl text-white font-bold block md:hidden"
            onClick={() => {
              toggleExpansion(true);
            }}
          >
            &#9776;
          </span>
          <ul className="md:flex text-white items-center hidden header-navigations">
            <li
              className="xl:ml-16 md:ml-8  cursor-pointer relative"
              onMouseOver={() => {
                togglepackages(true);
              }}
              onMouseLeave={() => {
                togglepackages(false);
              }}
            >
              <span className="flex items-center">
                <span className="mr-2">Packages</span>
                <p>▾</p>
              </span>
              {packages && (
                <div className="absolute  pt-6 z-10">
                  <ul className="bg-white p-3 text-sm  rounded-md package-dropdown ">
                    {Packages.edges.map((edge) => {
                      return (
                        <li className="mb-2 ">
                          <Link
                            className="flex justify-between items-center dropdown-item"
                            to={edge.node.frontmatter.path}
                          >
                            <div className="w-4/5 mt-2 text-gray-800">
                              <span className="font-bold">
                                {edge.node.frontmatter.nav}
                              </span>
                              <p className="mt-1 text-textcolor">
                                {edge.node.frontmatter.subtitle}
                              </p>
                            </div>

                            <div className="w-1/6">
                              <div className="w-2/3">
                                <img
                                  alt="logo"
                                  src={edge.node.frontmatter.logo.publicURL}
                                ></img>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </li>

            <li className="xl:ml-16 md:ml-8 cursor-pointer">
              <Link to="/about" className="text-white">
                About
              </Link>
            </li>

            <li
              className="xl:ml-16 md:ml-8  cursor-pointer relative"
              onMouseOver={() => {
                tooglefeatures(true);
              }}
              onMouseLeave={() => {
                tooglefeatures(false);
              }}
            >
              <Link to="/features" className="flex items-center">
                <span className="mr-2">Features</span>
                <p>▾</p>
              </Link>
              {features && (
                <div className="absolute  pt-5 z-10">
                  <ul className="bg-white p-3 text-sm  features-dropdown  rounded-md w-40">
                    {Features.edges.map((edge) => {
                      return (
                        <li className="mb-2">
                          <Link
                            className=" opacity-75 hover:opacity-100 dropdown-item"
                            to={edge.node.frontmatter.path}
                            onClick={() => {
                              tooglefeatures(!features);
                            }}
                          >
                            <span className="text-textcolor">
                              {edge.node.frontmatter.nav}
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                    <li>
                      <ul className="mt-2 pt-2 border-t border-textcolor">
                        {Commonfeatures.edges.map((edge) => {
                          return (
                            <li className="mb-2 ">
                              <Link
                                className="opacity-75 hover:opacity-100 dropdown-item"
                                to={edge.node.frontmatter.path}
                                onClick={() => {
                                  tooglefeatures(!features);
                                }}
                              >
                                <span className="text-textcolor">
                                  {edge.node.frontmatter.title}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </li>

            <li className="xl:ml-16 md:ml-8 cursor-pointer">
              <Link to="/showcase" className="text-white">
                Showcase
              </Link>
            </li>

            <li className="xl:ml-16 md:ml-8 cursor-pointer">
              <a
                rel="noreferrer noopener"
                href="http://docs.merlinpanel.com/"
                target="_blank"
                className="text-white"
              >
                Docs
              </a>
            </li>

            <li className="xl:ml-16 md:ml-8 cursor-pointer">
              {!!user ? (
                <div className="menu-avatar">
                  <img alt="Avatar" src={user?.avatar} />
                </div>
              ) : (
                <a
                  rel="noreferrer noopener"
                  href="https://app.merlinpanel.com/"
                  target="_blank"
                >
                  <button className="bg-transparent text-sm border border-white text-white rounded-md px-6 py-2 outline-none btn-purple-on-white">
                    Login
                  </button>
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
